import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueAlertify from 'vue-alertify'
import VueApexCharts from 'vue-apexcharts'
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMask from 'v-mask'

var numeral = require('numeral')

Vue.config.productionTip = false
Vue.use(VueAlertify)
Vue.use(VueColumnsResizableVuetify)
Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0')
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueMask)

new Vue({
  data: {
    imgNav: "require('@/assets/images/avatars/1.png')",
  },
  router,
  store,
  vuetify,

  render: h => h(App),
}).$mount('#app')
