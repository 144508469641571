import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth'
import pages from './pages'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    meta: {
      layout: 'content',
      public: false,
      permission: 'view reports',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view reports',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  ...auth,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '**',
    redirect: 'error-404',
  },
]

async function afterEach(to, from, next) {
  await router.app.$nextTick()
}

const hasUserRole = role => {
  return store.getters['auth/hasUserRole'](role)
}
const hasUserPermission = permission => {
  return store.getters['auth/hasUserPermission'](permission)
}

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
const beforeEach = async (to, from, next) => {
  await store.dispatch('auth/autoLogin')

  if (to.matched.some(record => record.meta.public)) {
    if (
      (to.name == 'auth-login' && store.state.auth.authenticated === true) ||
      (to.name == 'auth-register' && store.state.auth.authenticated === true)
    ) {
      console.log(store.state.auth)
      return next('/')
    }
    return next()
  } else if (to.name === 'dashboard') {
    if (to.matched.some(record => hasUserPermission(record.meta.permission))) {
      return next()
    } else {
      return next({ name: 'request' })
    }
  } else if (to.matched.some(record => hasUserPermission(record.meta.permission))) {
    return next()
  } else {
    // Redirect to login if not logged in
    if (!store.state.auth.authenticated) {
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  return next()
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
