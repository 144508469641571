import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    loggingIn: false,
    accessToken: null,
    impersonation: false,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_IMPERSONATION(state, value) {
      state.impersonation = value
    },
    SET_LOGGING_IN(state, value) {
      state.loggingIn = value
    },
    SET_PROFILE(state, value) {
      state.user.profile = {
        ...state.user.profile,
        ...value,
      }
    },
    SET_AVATAR(state, value) {
      state.user.profile.avatar = value
    },

    SET_USER(state, value) {
      state.user = value
    },
    SET_ACCESS_TOKEN(state, value) {
      state.accessToken = value
    },

    SET_IMPERSONATE(state, value) {
      state.user = value
    },
    SET_IMPERSONATE_TOKEN(state, value) {
      state.accessToken = value
    },
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
    hasUserRole(state) {
      return role => {
        if (!role) return true

        let qRoles

        if (typeof role === 'string') qRoles = [role]
        else qRoles = role

        return state.user?.roles.filter(r => qRoles.includes(r.name)).length > 0
      }
    },
    hasUserPermission(state) {
      return permission => {
        if (!state.authenticated) return false
        if (!permission) return true

        let ret = false
        state.user.roles.every(role => {
          ret ||= role.permissions.findIndex(p => p.name === permission) !== -1

          return !ret
        })

        return ret
      }
    },
  },
  actions: {
    async getCookie() {
      await http.get('/sanctum/csrf-cookie')
    },
    getToken({ commit }) {
      const accessToken = localStorage.getItem('accessToken')
      const impersonateToken = localStorage.getItem('impersonateToken')
      if (impersonateToken) {
        commit('SET_IMPERSONATE_TOKEN', impersonateToken)
      } else if (accessToken) {
        commit('SET_ACCESS_TOKEN', accessToken)
      }
    },
    autoLogin({ commit }) {
      const accessToken = localStorage.getItem('accessToken')
      const impersonateToken = localStorage.getItem('impersonateToken')
      if (impersonateToken) {
        const user = localStorage.getItem('impersonateData')
        commit('SET_IMPERSONATE_TOKEN', impersonateToken)
        commit('SET_IMPERSONATE', JSON.parse(user))
        commit('SET_AUTHENTICATED', true)
        commit('SET_IMPERSONATION', true)
      } else if (accessToken) {
        const user = localStorage.getItem('userData')
        commit('SET_ACCESS_TOKEN', accessToken)
        commit('SET_USER', JSON.parse(user))
        commit('SET_AUTHENTICATED', true)
      }
    },
    impersonate({ commit }, formData) {
      commit('SET_LOGGING_IN', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/impersonate', formData)
          .then(response => {
            if (response && response.data) {
              const { accessToken, user } = response.data
              localStorage.setItem('impersonateToken', accessToken)
              localStorage.setItem('impersonateData', JSON.stringify(user))
              commit('SET_IMPERSONATE_TOKEN', accessToken)
              commit('SET_IMPERSONATE', user)
              resolve(response)
            }
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOGGING_IN', false))
      })
    },
    login({ commit }, formData) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGGING_IN', true)
        axios
          .post('/auth/login', formData)
          .then(response => {
            if (response && response.data) {
              const { accessToken, user } = response.data
              localStorage.setItem('accessToken', accessToken)
              localStorage.setItem('userData', JSON.stringify(user))
              commit('SET_ACCESS_TOKEN', accessToken)
              resolve(response)
            }
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOGGING_IN', false))
      })
    },
    register({ commit }, formData) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGGING_IN', true)
        axios
          .post('/auth/register', formData)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOGGING_IN', false))
      })
    },
    getUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('user')
          .then(resp => {
            const { data } = resp
            commit('SET_USER', data)
            commit('SET_AUTHENTICATED', true)
            resolve(resp)
          })
          .catch(err => {
            // Remove tokens from localStorage
            localStorage.removeItem('accessToken')
            localStorage.removeItem('impersonateToken')

            // Remove userData & Ability from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem('impersonateData')
            localStorage.removeItem('userProfile')
            localStorage.removeItem('userAbility')

            commit('SET_USER', {})
            commit('SET_AUTHENTICATED', false)
            commit('SET_IMPERSONATION', false)
            commit('SET_ACCESS_TOKEN', null)
            commit('SET_IMPERSONATE_TOKEN', null)

            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/logout')
          .then(response => {
            // Remove tokens from localStorage
            localStorage.removeItem('accessToken')
            localStorage.removeItem('impersonateToken')

            // Remove userData & Ability from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem('impersonateData')
            localStorage.removeItem('userProfile')
            localStorage.removeItem('userAbility')

            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_USER', {})
            commit('SET_AUTHENTICATED', false)
            commit('SET_IMPERSONATION', false)
            commit('SET_ACCESS_TOKEN', null)
            commit('SET_IMPERSONATE_TOKEN', null)
          })
      })
    },
    stopImpersonate({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/impersonate/stop')
          .then(response => {
            if (response && response.data) {
              //remove impersonation data
              localStorage.removeItem('impersonateData')
              localStorage.removeItem('impersonateToken')
              commit('SET_IMPERSONATION', false)
              //set account data
              const accessToken = localStorage.getItem('accessToken')
              const user = localStorage.getItem('userData')

              if (accessToken) {
                commit('SET_ACCESS_TOKEN', accessToken)
                commit('SET_USER', JSON.parse(user))
              }
            }
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => {})
      })
    },
    getInvitation({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/user-invitation', formData)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    forgotPassword({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/forgot-password', formData)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    resetPassword({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/reset-password', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },

    updateProfile({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/user/update`, data)
          .then(async response => {
            if (response && response.data) {
              if (response.data.owner) {
                commit('SET_PROFILE', response.data.profile)
                // commit('SET_AVATAR', response.data.profile.avatar)

                const accessToken = localStorage.getItem('accessToken')
                const impersonateToken = localStorage.getItem('impersonateToken')
                if (impersonateToken) {
                  let user = JSON.parse(localStorage.getItem('impersonateData'))
                  user.profile.avatar = response.data.profile.avatar
                  localStorage.setItem('impersonateData', JSON.stringify(user))
                } else if (accessToken) {
                  let user = JSON.parse(localStorage.getItem('userData'))
                  user.profile.avatar = response.data.profile.avatar
                  localStorage.setItem('userData', JSON.stringify(user))
                }
              }
            }
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },

    changePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/user/change-password`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },

    autoRegister({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/auth/auto-register`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
