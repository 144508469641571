import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    uploadAvatar({ commit }, file) {
      const formData = new FormData()
      formData.append('avatar', file)
      const headers = { 'Content-Type': 'multipart/form-data' }

      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/user/avatar`, formData, { headers })
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
