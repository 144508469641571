import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    allUsers({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/user/all?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    changePasswordAccount({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/user/account/change-password`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getUser({ commit }, user_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/info/${user_id}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
