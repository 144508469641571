<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <v-img :src="avatarImg"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
            <v-img :src="avatarImg"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span v-if="userProfile" class="text--primary font-weight-semibold mb-n1">
            {{ userName }}
          </span>
          <small v-if="userProfile" class="text--disabled text-capitalize">{{ role }}</small>
          <small v-else class="text--disabled text-capitalize">Admin</small>
          <small v-if="impersonation" class="impersonation">Impersonation</small>
        </div>
      </div>

      <v-divider v-if="impersonation" class="my-2"></v-divider>

      <!-- Profile -->
      <v-list-item v-if="impersonation" class="btn-impersonation" @click="closeImpersonate">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountRemove }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Stop Impersonation</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'profile', params: { id: 1 } }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Billing -->
      <v-list-item :to="{ name: 'billing' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCreditCardOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Billing</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Metrics -->
      <v-list-item :to="{ name: 'superadmin-dashboard' }" v-if="hasUserRole('Super Admin')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiFinance }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Metrics </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Chat -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>

      <!-- Settings -->
      <v-list-item :to="{ name: 'settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiCreditCardOutline,
  mdiAccountRemove,
  mdiFinance,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { computed, getCurrentInstance } from '@vue/composition-api'
import { initialAbility } from '@/plugins/acl/config'
//store
import store from '../store'
import { mapActions, mapGetters } from 'vuex'
//images
import avatar from '@/assets/images/profiles/avatar.png'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    // store getters
    const hasUserRole = role => {
      return store.getters['auth/hasUserRole'](role)
    }
    const hasUserPermission = permission => {
      return store.getters['auth/hasUserPermission'](permission)
    }

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const stopImpersonate = async () => {
      return await store.dispatch('auth/stopImpersonate', {}).then(async resp => {
        if (resp && resp.data) {
          if (!(router.history.current.name == 'dashboard')) {
            router.push('/')
          }
        }
      })
    }
    const closeImpersonate = async () => {
      await stopImpersonate().then(resp => {
        if (resp && resp.data) {
          if (!(router.history.current.name == 'dashboard')) {
            router.push('/')
          }
        }
      })
    }

    const userData = computed(() => {
      if (store.state.auth.user) {
        return store.state.auth.user
      }
    })

    const userProfile = computed(() => {
      if (store.state.auth.user) {
        return store.state.auth.user.profile
      }
    })

    const avatarImg = computed(() => {
      if (store.state.auth.user) {
        if (store.state.auth.user.profile) {
          return store.state.auth.user.profile ? store.state.auth.user.profile.avatar : avatar
        }
      }
    })

    const userName = computed(() => {
      if (store.state.auth.user) {
        if (store.state.auth.user.profile) {
          let name = ''
          if (userProfile.value.first_name) {
            name = userProfile.value?.first_name?.split(' ')[0]
          }
          // if (userProfile.value?.last_name) {
          //   name += ' ' + userProfile.value?.last_name?.split(' ')[0]
          // }
          return name
        }
      }
    })

    const role = computed(() => {
      if (store.state.auth.user) {
        if (store.state.auth.user.profile) {
          return store.state.auth.user.roles[0] ? store.state.auth.user.roles[0].name : ''
        }
      }
    })

    const impersonation = computed(() => store.state.auth.impersonation)

    const logoutUser = async () => {
      await store.dispatch('auth/logout')

      // Redirect to login page
      router.go({ name: 'auth-login' })
    }

    return {
      hasUserRole,
      hasUserPermission,
      logoutUser,
      closeImpersonate,

      userData,
      userName,
      role,
      userProfile,
      avatarImg,
      impersonation,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiCreditCardOutline,
        mdiAccountRemove,
        mdiFinance,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>

<style lang="scss" scoped>
.impersonation {
  text-align: center;
  margin-top: 5px;
  background: #6cb2eb;
  color: #000;
  padding: 5px;
  border-radius: 10px;
}
</style>
