<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    max-width="400"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        top
        color="warning"
        :value="notifications.length"
        :content="notifications.length"
        overlap
        offset-x="12"
        offset-y="12"
        class="me-2"
      >
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>
    <v-card v-if="notifications.length === 0">
      <v-card-text>
        No notifications found.
      </v-card-text>
    </v-card>
    <v-list v-if="notifications.length > 0" :class="darkTheme ? 'notifications-size bg-dark' : 'notifications-size bg-light'">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-expansion-panels >
        <v-expansion-panel
          v-for="notification in notifications"
          :key="notification.id"
        >
          <v-expansion-panel-header>
            Report {{ notification.data.report ? notification.data.report.report_type : null }} created successfully
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn :href="notification.data.report ? notification.data.report.url_report : null">Go to report</v-btn>

             <v-icon
              @click="remove(notification.id)"
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider class="my-2"></v-divider>

      <v-list-item @click="markAsRead">
        <v-list-item-icon >
          <v-icon size="22">
            {{ icons.mdiDelete }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Clear all</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiBellOutline,
  mdiDelete,
  mdiCloseCircleOutline,
} from '@mdi/js'
import axios from '@axios'
import themeConfig from '@themeConfig'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiBellOutline,
        mdiDelete,
        mdiCloseCircleOutline,
      },
    }
  },
  data() {
    return {
      notifications: [],
    }
  },
  computed: {
    darkTheme() {
      return this.$vuetify.theme.isDark;
    }
  },
  mounted() {
    this.getNotifications()
    setInterval(() => {
      this.getNotifications()
    }, 10000)
  },
  methods: {
    getNotifications() {
      axios
        .get('/notifications')
        .then(response => {
          this.notifications = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    markAsRead() {
      this.notifications = []
      axios
        .post('/notifications/mark-all-as-read')
        .then(response => {
          console.log('mark as read')
        })
        .catch(error => {
          console.log(error)
        })
    },
    remove(id) {
      axios
        .post('/notifications/mark-as-read', { notification_id: id })
        .then(response => {
          console.log('mark as read')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
  .notifications-size{
    max-height: 400px;
  }
  .bg-dark{
    background: rgba(#f4f5fa, 0.1) !important
  }
  .bg-light{
    background: rgba(var(--primary-shade--dark), 0.1) !important
  }
</style>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
