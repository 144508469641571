import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    // TODO hacer el controlador
    getPayments({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get('/payment/get')
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getServices({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get('/payment/services')
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSuscriptionInfo({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get('/payment/getSuscriptionInfo')
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getHistory({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/payment/history?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getDetail({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/payment/detail?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    savePaymentProfile({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post('/payment/create', data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    paymentProcess({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/payment/paymentProcess`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    deletePayment({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .delete(`/payment/delete/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
