import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'

import app from './app/app'
import auth from './modules/auth'
import keyword_research from './modules/keyword_research'
import semantic_analysis from './modules/semantic_analysis'
import serp_analysis from './modules/serp_analysis'
import full_reports from './modules/full_reports'
import settings from './modules/settings'
import snackbar from './modules/snackbar'
import invitations from './modules/invitations'
import role from './modules/role'
import requests from './modules/requests'
import dashboard from './modules/dashboard'
import share from './modules/share'
import report_bug from './modules/report_bug'
import payment from './modules/payment'
import profile from './modules/profile'
import client from './modules/client'
import super_admin from './modules/super_admin'
import user from './modules/user'
import request_feature from './modules/request_feature'
import billing_address from './modules/billing_address'
import remaining_report from './modules/remaining_report'
import subscription from './modules/subscription'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [],
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    snackbar,
    serp_analysis,
    semantic_analysis,
    keyword_research,
    full_reports,
    settings,
    invitations,
    role,
    requests,
    dashboard,
    share,
    report_bug,
    payment,
    profile,
    client,
    super_admin,
    user,
    request_feature,
    billing_address,
    remaining_report,
    subscription,
  },
})
