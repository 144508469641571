import {
  mdiChartBar,
  mdiFileOutline,
  mdiAccountGroup,
  mdiHomeOutline,
  mdiTextBoxSearchOutline,
  mdiFileChartOutline,
  mdiChartBoxOutline,
  mdiTextBoxPlusOutline,
  mdiRocketLaunchOutline,
  mdiShieldAccountOutline,
  mdiFaceAgent,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
    permission: 'view reports',
  },
  {
    title: 'Requests',
    icon: mdiRocketLaunchOutline,
    to: 'request',
    permission: 'request token',
  },
  {
    title: 'Create Reports',
    icon: mdiTextBoxSearchOutline,
    permission: 'create reports',
    children: [
      {
        title: 'Keyword Research',
        to: 'keyword-research-new',
        icon: mdiTextBoxPlusOutline,
        permission: 'create keyword research',
      },
      {
        title: 'Semantic Report',
        to: 'semantic-analysis-new',
        icon: mdiTextBoxPlusOutline,
        permission: 'create semantic analysis',
      },
      {
        title: 'Serp Report',
        to: 'serp-analysiss-new',
        icon: mdiTextBoxPlusOutline,
        permission: 'create serp analysis',
      },
      {
        title: 'Full Report',
        to: 'full-report-new',
        icon: mdiTextBoxPlusOutline,
        permission: 'create full report',
      },
    ],
  },
  {
    title: 'Reports',
    icon: mdiChartBar,
    permission: 'view reports',
    children: [
      {
        title: 'Keyword Research',
        to: 'keyword-research-history',
        icon: mdiChartBoxOutline,
        permission: 'view keyword research',
      },
      {
        title: 'Semantic Analysis',
        to: 'semantic-analysis-list',
        icon: mdiChartBoxOutline,
        permission: 'view semantic analysis',
      },
      {
        title: 'SERP Analysis',
        to: 'serp-analysis-list',
        icon: mdiChartBoxOutline,
        permission: 'view serp analysis',
      },
      {
        title: 'Full Reports',
        to: 'full-report-list',
        icon: mdiChartBoxOutline,
        permission: 'view full report',
      },
    ],
  },
  {
    title: 'Admin',
    icon: mdiShieldAccountOutline,
    permission: 'manage accounts',
    children: [
      {
        title: 'Manage Users',
        to: 'account-users',
        permission: 'manage users',
      },
      {
        title: 'Invitations',
        to: 'invitations',
        permission: 'invite users',
      },
    ],
  },
  {
    title: 'Support',
    icon: mdiAccountGroup,
    permission: 'support accounts',
    children: [
      {
        title: 'Organizations',
        to: 'organizations',
        permission: 'support accounts',
      },
      {
        title: 'Account Requests',
        to: 'requestSubmitted',
        permission: 'support accounts',
      },
      {
        title: 'Reported Bugs',
        to: 'report-bug-list',
        permission: 'support accounts',
      },
      {
        title: 'Features Requested',
        to: 'request-feature-list',
        permission: 'support accounts',
      },
      {
        title: 'Roles',
        to: 'role',
        permission: 'support accounts',
      },
    ],
  },
  {
    title: 'Contact',
    icon: mdiFaceAgent,
    children: [
      {
        title: 'Report Bugs',
        to: 'report-bugs',
      },
      {
        title: 'Request Features',
        to: 'request-feature',
      },
    ],
  },
]
