<template>
  <layout-content-vertical-nav :nav-menu-items="navigationItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="me-3" @click="toggleVerticalNavMenuActive">
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="
              handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)
            "
          ></app-bar-search>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-notifications></app-bar-notifications>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-center">
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a href="https://www.97thfloor.com/" target="_blank">97thfloor</a>, All rights Reserved.</span
        >
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import { mdiMenu, mdiHeartOutline, mdiAccountGroup } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { ref, watch, computed } from '@vue/composition-api'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation'
import { useRouter } from '@core/utils'

//store
import store from '@/store'

// App Bar Components
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarNotifications from '@/components/AppBarNotifications.vue'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
    AppBarNotifications,
  },
  setup() {
    const $vuetify = getVuetify()
    const { route, router } = useRouter()

    const loading = ref(false)
    const errors = ref(null)

    const limit = ref(null)
    const tracking = ref(false)

    const user = computed(() => {
      return store.state.auth.user
    })
    const authenticated = computed(() => {
      return store.state.auth.authenticated
    })

    // store getters
    const hasUserRole = role => {
      return store.getters['auth/hasUserRole'](role)
    }
    const hasUserPermission = permission => {
      return store.getters['auth/hasUserPermission'](permission)
    }

    //Navigations
    const navigationItems = computed(() => {
      if (!authenticated) return []

      const nav_items = navMenuItems.reduce((result, menu) => {
        if (hasUserPermission(menu.permission)) {
          let item = { ...menu }
          if (menu.children) {
            item.children = item.children.filter(submenu => {
              return hasUserPermission(submenu.permission)
            })
          }
          result.push(item)
        }
        return result
      }, [])

      return nav_items
    })

    // store actions
    const getLimitReport = async () => {
      loading.value = true
      return await store
        .dispatch('remaining_report/limit', '')
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              tracking.value = resp.data.data.tracking
              limit.value = resp.data.data.limit
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false
        })
    }

    watch(user, () => {
      getLimitReport()
    })

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const goTo = (route, query) => {
      return router.push({ name: route, query: query })
    }

    return {
      loading,
      errors,

      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      mdiAccountGroup,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },

      //Navigation
      navigationItems,

      user,
      authenticated,

      //Navigation
      navigationItems,
      goTo,
      limit,
      tracking,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
