import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    getShared({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/share/getShared?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    generateLink({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/share/generateLink?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    generateNewLink({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/share/generateNewLink?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    shareAndSendEmail({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/share/shareAndSendEmail?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSharedEmails({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/share/getSharedEmails?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    remove({ commit }, token) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .delete(`/share/remove/${token}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    removeAll({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .delete(`/share/removeAll?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  }

}
