const pages = [
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/landing-page',
    name: 'landing-page',
    component: () => import('@/views/pages/landing/LandingPage.vue'),
    meta: {
      layout: 'blank',
      public: true,
      auth: false,
    },
  },

  /*----------------------------- Share ------------------------------*/

  {
    path: '/share/:token',
    name: 'share',
    component: () => import('@/views/pages/share/Share.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },

  /* ------------------------- Keyword Reports --------------------- */
  {
    path: '/kw-research/new',
    name: 'keyword-research-new',
    component: () => import('@/views/pages/keyword-research/NewKeywordResearch.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'create keyword research',
    },
  },
  {
    path: '/kw-research/await/:keyword_research_id',
    name: 'keyword-research-await',
    component: () => import('@/views/pages/keyword-research/AwaitKeywordResearch.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view keyword research',
    },
  },
  {
    path: '/kw-research/report/:keyword_research_id',
    name: 'keyword-research-report',
    component: () => import('@/views/pages/keyword-research/ReportKeywordResearch.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view keyword research',
    },
  },
  {
    path: '/kw-research/history',
    name: 'keyword-research-history',
    component: () => import('@/views/pages/keyword-research/HistoryKeywordResearch.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view keyword research',
    },
  },

  /* ------------------------- Semantic Analysis --------------------- */
  {
    path: '/semantic-analysis/new',
    name: 'semantic-analysis-new',
    component: () => import('@/views/pages/semantic-analysis/NewReport.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'create semantic analysis',
    },
  },
  {
    path: '/semantic-analysis/history',
    name: 'semantic-analysis-list',
    component: () => import('@/views/pages/semantic-analysis/SemanticHistory.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view semantic analysis',
    },
  },
  {
    path: '/semantic-analysis/report/:report_id/:token?',
    name: 'semantic-analysis-report',
    component: () => import('@/views/pages/semantic-analysis/SemanticReport.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view semantic analysis',
    },
  },
  {
    path: '/semantic-analysis/await/:report_id',
    name: 'semantic-analysis-await',
    component: () => import('@/views/pages/semantic-analysis/AwaitResults.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view semantic analysis',
    },
  },

  /* ------------------------- SERP Analysis --------------------- */
  {
    path: '/serp-analysis/new',
    name: 'serp-analysiss-new',
    component: () => import('@/views/pages/serp-analysis/NewReport.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'create serp analysis',
    },
  },
  {
    path: '/serp-analysis/list',
    name: 'serp-analysis-list',
    component: () => import('@/views/pages/serp-analysis/ReportList.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view serp analysis',
    },
  },
  {
    path: '/serp-analysis/report/:report_id/:token?',
    name: 'serp-analysis-report',
    component: () => import('@/views/pages/serp-analysis/SerpReport.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view serp analysis',
    },
  },
  {
    path: '/serp-analysis/await/:report_id',
    name: 'serp-analysis-await',
    component: () => import('@/views/pages/serp-analysis/AwaitResults.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view serp analysis',
    },
  },

  /*----------------------------- Full Report ------------------------------*/
  {
    path: '/full-report/new',
    name: 'full-report-new',
    component: () => import('@/views/pages/full-reports/NewReport.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'create full report',
    },
  },
  {
    path: '/full-report/list',
    name: 'full-report-list',
    component: () => import('@/views/pages/full-reports/FullReportList.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view full report',
    },
  },
  {
    path: '/full-report/report/:report_id/:token?',
    name: 'full-report',
    component: () => import('@/views/pages/full-reports/FullReport.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view full report',
    },
  },
  {
    path: '/full-report/await/:report_id',
    name: 'full-report-await',
    component: () => import('@/views/pages/full-reports/AwaitResults.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'view full report',
    },
  },

  /*----------------------------- Profile ------------------------------*/
  {
    path: '/profile/view/:id',
    name: 'profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/pages/customize/Theme.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },

  /*----------------------------- Admin ------------------------------*/

  {
    path: '/account/users',
    name: 'account-users',
    component: () => import('@/views/pages/users/ManageUsers.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage users',
    },
  },
  {
    path: '/account/user/:user_id',
    name: 'account-user-info',
    component: () => import('@/views/pages/users/UserDetail.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage users',
    },
  },
  {
    path: '/account/user/change-password/:user_id',
    name: 'account-change-password',
    component: () => import('@/views/pages/users/ChangePassword.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage users',
    },
  },

  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/pages/roles/List.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage roles',
    },
  },
  {
    path: '/role/edit',
    name: 'roleEdit',
    component: () => import('@/views/pages/roles/Edit.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage roles',
    },
  },
  {
    path: '/role/create',
    name: 'createRole',
    component: () => import('@/views/pages/roles/Create.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage roles',
    },
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: () => import('@/views/pages/invitations/Invitations.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'invite users',
    },
  },

  /*----------------------------- Support ------------------------------*/

  {
    path: '/support/organizations',
    name: 'organizations',
    component: () => import('@/views/pages/organization/Organizations.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage accounts',
    },
  },
  {
    path: '/support/organization/:client_id/edit',
    name: 'organization-edit',
    component: () => import('@/views/pages/organization/OrganizationEdit.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage accounts',
    },
  },
  {
    path: '/support/organization/:client_id/users',
    name: 'organization-users',
    component: () => import('@/views/pages/organization/OrganizationUsers.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage accounts',
    },
  },
  {
    path: '/support/organization/user/:user_id',
    name: 'organization-user-info',
    component: () => import('@/views/pages/users/UserDetail.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage users',
    },
  },
  {
    path: '/support/requests/list',
    name: 'requestSubmitted',
    component: () => import('@/views/pages/request/submitted/RequestSubmitted.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage accounts',
    },
  },
  {
    path: '/support/requests/review/:request_id',
    name: 'requestReview',
    component: () => import('@/views/pages/request/review/RequestReview.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage accounts',
    },
  },
  {
    path: '/support/organization/change/:user_id',
    name: 'organization-change',
    component: () => import('@/views/pages/organization/ChangeOrganization.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'manage accounts',
    },
  },

  /*----------------------------- Request Join ------------------------------*/

  {
    path: '/request',
    name: 'request',
    component: () => import('@/views/pages/request/Request.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'request token',
    },
  },
  {
    path: '/request/account',
    name: 'requestAccount',
    component: () => import('@/views/pages/request/client/RequestAccount.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'request token',
    },
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('@/views/pages/request/join/Join.vue'),
    meta: {
      layout: 'content',
      public: false,
      permission: 'request token',
    },
  },

  /*----------------------------- Contact ------------------------------*/

  {
    path: '/report-bugs',
    name: 'report-bugs',
    component: () => import('@/views/pages/report-bugs/SendReportBug.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },
  {
    path: '/report-bug/details/:report_bug_id',
    name: 'report-bug-details',
    component: () => import('@/views/pages/report-bugs/ReportDetails.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },
  {
    path: '/report-bug-list',
    name: 'report-bug-list',
    component: () => import('@/views/pages/report-bugs/ReportBugList.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },

  {
    path: '/request-feature',
    name: 'request-feature',
    component: () => import('@/views/pages/request-feature/SendRequestFeature.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },
  {
    path: '/request-feature-list',
    name: 'request-feature-list',
    component: () => import('@/views/pages/request-feature/RequestFeatureList.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },
  {
    path: '/request-feature/details/:request_feature_id',
    name: 'request-feature-details',
    component: () => import('@/views/pages/request-feature/RequestFeatureDetails.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },

  /*----------------------------- Billing  ------------------------------*/

  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/pages/billing/Billing.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },

  /*----------------------------- Payment  ------------------------------*/

  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/pages/payment/Payment.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },
  {
    path: '/payment/detail/:payment_id',
    name: 'payment-detail',
    component: () => import('@/views/pages/billing/components/PaymentHistory/PaymentDetail.vue'),
    meta: {
      layout: 'content',
      public: false,
    },
  },

  /*----------------------------- Super Admin  ------------------------------*/

  {
    path: '/super-admin/dashboard',
    name: 'superadmin-dashboard',
    component: () => import('@/views/pages/superadmin/dashboard'),
    meta: {
      layout: 'content',
      public: false,
    },
  },
]

export default pages
