<template>
  <layout-content-horizontal-nav :nav-menu-items="navigationItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar>
      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
          <router-link to="/" class="d-flex align-center text-decoration-none">
            <v-img :src="appLogo" max-height="200px" max-width="200px" alt="logo" contain class="me-3"></v-img>
          </router-link>
        </div>

        <div class="d-flex align-center" v-if="tracking && !limit > 0">
          <div class="account-status">
            <v-alert dense outlined type="warning" class="mt-4">
              Your account has run out of reports. You can add more reports to your account by
              <strong class="link-subcription" @click="goTo('billing', { tab: 'plans' })">clicking here.</strong>
            </v-alert>
          </div>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <app-bar-notifications />
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
          ></app-bar-search>
          <app-bar-theme-switcher></app-bar-theme-switcher>
          <app-bar-user-menu class="ms-2"></app-bar-user-menu>
        </div>
      </div>

      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-center">
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a href="https://www.97thfloor.com/" target="_blank">97thfloor</a>, All rights Reserved.</span
        >
      </div>
    </template>

    <!-- App Content -->
    <!-- <template #v-app-content>
      <app-customizer class="d-none d-md-block"></app-customizer>
    </template> -->
  </layout-content-horizontal-nav>
</template>

<script>
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { ref, watch, computed, onBeforeMount, onBeforeUpdate } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { mdiHeartOutline, mdiAccountGroup, mdiChartBar, mdiFileOutline, mdiHomeOutline } from '@mdi/js'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarNotifications from '@/components/AppBarNotifications.vue'
import { useRouter } from '@core/utils'

//store
import store from '@/store'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import navMenuItems from '@/navigation'
import { mapGetters } from 'vuex'

export default {
  components: {
    LayoutContentHorizontalNav,
    AppCustomizer,

    // App Bar Components
    AppBarSearch,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotifications,
  },
  setup() {
    const { route, router } = useRouter()

    const loading = ref(false)
    const errors = ref(null)

    const limit = ref(null)
    const tracking = ref(false)

    const user = computed(() => {
      return store.state.auth.user
    })
    const authenticated = computed(() => {
      return store.state.auth.authenticated
    })

    // store getters
    const hasUserRole = role => {
      return store.getters['auth/hasUserRole'](role)
    }
    const hasUserPermission = permission => {
      return store.getters['auth/hasUserPermission'](permission)
    }

    // store actions
    const getLimitReport = async () => {
      loading.value = true
      return await store
        .dispatch('remaining_report/limit', '')
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              tracking.value = resp.data.data.tracking
              limit.value = resp.data.data.limit
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          loading.value = false
        })
    }

    watch(user, async () => {
      await getLimitReport()
    })

    //Navigations
    const navigationItems = computed(() => {
      if (!authenticated) return []

      const nav_items = navMenuItems.reduce((result, menu) => {
        if (hasUserPermission(menu.permission)) {
          let item = { ...menu }
          if (menu.children) {
            item.children = item.children.filter(submenu => {
              return hasUserPermission(submenu.permission)
            })
          }
          result.push(item)
        }
        return result
      }, [])

      return nav_items
    })

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    const goTo = (route, query) => {
      return router.push({ name: route, query: query })
    }

    return {
      loading,
      errors,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      mdiAccountGroup,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },

      user,
      authenticated,

      //Navigation
      navigationItems,

      goTo,
      limit,
      tracking,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.account-status {
  box-sizing: border-box;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 20px;
  color: var(--v-warning-base);
}

.link-subcription {
  cursor: pointer;
}
</style>
